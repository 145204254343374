/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
// Index .less, any imports here will be included in the final css build

@import '~bootstrap/less/bootstrap.less';
@import './fonts.less';
@import './variables.less';
@import './cosmo/bootswatch.less';

html,
body {
  font-size: @font-size-base;
  line-height: @line-height-base;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  flex: 0 1 auto;
}

#app {
  flex: 1 1 auto;
  position: relative;
  display: flex;
  flex-direction: column;
}


/* Customs */


a, a:focus, a:hover {
  color: #f20024!important;
}

.ant-menu-horizontal>.ant-menu-item a,
.ant-menu-horizontal>.ant-menu-item-selected a,
.ant-menu-horizontal>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-horizontal>.ant-menu-submenu>.ant-menu-submenu-title .fa.fa-plus{
  color: #f20024!important;
}

#main-menu .ant-menu-item a:hover {
  color: #f20024!important;
  border-color: #f20024!important;
}

#main-menu .ant-menu-item a::after {
  background-color: #f20024!important;
}
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover {
  border-bottom: 2px solid #f20024!important;
  color: #f20024!important;
}

#main-menu .ant-menu:not(.ant-menu-dark) > .ant-menu-submenu:hover,
#main-menu .ant-menu:not(.ant-menu-dark) > .ant-menu-item:hover {
  border-bottom: none!important;
}

.menu .ant-menu-item li.active a,
.menu .ant-menu-item div.active a {
  background: #f20024!important;
  color: #fff!important;
}

.ant-menu-submenu-title span[role="img"] svg,
span[aria-label="more-vert"],
.collapse-icon > svg {
  color: #f20024!important;
}

.ant-btn.superset-button:not([disabled]),
.btn-primary:not([disabled]) {
  color: #f20024!important;
  background-color: #fff!important;
  border: 1px solid #f20024!important;
}

.ant-btn.superset-button:hover,
.btn-primary:hover,
.pagination-container li.active span {
  color: #fff!important;
  background-color: #f20024!important;
}

.ant-tabs,
.text-primary, .text-primary:hover,
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs>.ant-tabs-nav .ant-tabs-nav-add:hover,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-add:hover,
.ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-nav .ant-tabs-tab:hover {
  color: #f20024!important;
}
.ant-menu-submenu .ant-menu-vertical .ant-menu-item a:hover {
  text-decoration: underline;
}

#js-sql-toolbar .ant-dropdown-button button.ant-btn.ant-btn-default:nth-child(2)::before, 
#js-sql-toolbar .ant-dropdown-button button.ant-btn.ant-btn-default:nth-child(2):hover::before {
  border-left-color: transparent;
}

#js-sql-toolbar .ant-dropdown-button button.ant-btn.ant-btn-default {
  background-color: #fff;
  color: #f20024;
  border-color: #f20024;
}

.ant-collapse-content .menu .ant-menu-item li.active > a,
.ant-collapse-content .menu .ant-menu-item li.active > div,
.ant-collapse-content .menu .ant-menu-item div.active > div,
.ant-collapse-content .menu .ant-menu-item li > a:hover,
.ant-collapse-content .menu .ant-menu-item li > a:focus,
.ant-collapse-content .menu .ant-menu-item li > div:hover,
.ant-collapse-content .menu .ant-menu-item div > div:hover,
.ant-collapse-content .menu .ant-menu-item div > a:hover {
  background: transparent!important;
  color: #f20024!important;
}